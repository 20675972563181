<template>
    <v-container fluid class="grey lighten-5 my-0 px-0 py-0">
        <q-support-submenu></q-support-submenu>
        <v-row justify="center" class="mx-5">
            <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
            <v-col v-if="!loading && ticket.id > 0" cols=12 sm="8">
                <v-row cols="12" class="mb-5 d-flex">
                    <v-col cols="1" flat color="transparent" class="pt-4 mr-3" max-width="75px" style="min-width:85px;">
                        <!--status display -->
                        <div v-if="ticket.status == 'new'" style="background-color:#0083EB;">
                            <div style="display:block; text-align:center; margin:0 auto;">
                                <v-icon class="pt-4" color="white" medium>fas fa-file-alt</v-icon>
                            </div>
                            <div class="pb-2 white--text" style="text-align:center;">
                                <span dark width="100%">
                                    {{ticket.status}}
                                </span>
                            </div>
                        </div>
                        <div v-if="ticket.status == 'open'" style="background-color:#6ECF93">
                            <div style="display:block; text-align:center; margin:0 auto;">
                                <v-icon class="pt-4" color="white" medium>fas fa-file-alt</v-icon>
                            </div>
                            <div class="pb-2 white--text" style="text-align:center;">
                                <span dark width="100%">
                                    {{ticket.status}}
                                </span>
                            </div>
                        </div>
                        <div v-if="ticket.status == 'pending'" style="background-color:#FF9F1C;">
                            <div style="display:block; text-align:center; margin:0 auto;">
                                <v-icon class="pt-4" color="white" medium>fas fa-file-alt</v-icon>
                            </div>
                            <div class="pb-2 white--text" style="text-align:center;">
                                <span dark width="100%">
                                    {{ticket.status}}
                                </span>
                            </div>
                        </div>
                        <div v-if="ticket.status == 'solved'" style="background-color:#6ECF93;">
                            <div style="display:block; text-align:center; margin:0 auto;">
                                <v-icon class="pt-4" color="white" medium>fas fa-check</v-icon>
                            </div>
                            <div class="pb-2 white--text" style="text-align:center;">
                                <span dark width="100%">
                                    {{ticket.status}}
                                </span>
                            </div>
                        </div>
                        <div v-if="ticket.status == 'closed'" style="background-color:#99CDF7;">
                            <div style="display:block; text-align:center; margin:0 auto;">
                                <v-icon class="pt-4" color="white" medium>fas fa-check-circle</v-icon>
                            </div>
                            <div class="pb-2 white--text" style="text-align:center;">
                                <span dark width="100%">
                                    {{ticket.status}}
                                </span>
                            </div>
                        </div>
						<hr>
						<v-dialog v-model="dialog" width="500">
							<v-card>
								<v-card-title class="headline grey lighten-2" :class="!ticketLoading && !ticketError && 'card-title--success'">
									{{ dialogText }}
									<v-progress-circular v-if="ticketLoading" class="ml-2" indeterminate color="primary" size="24" />
								</v-card-title>
								<v-card-actions v-if="ticketError">
									<v-btn class="ml-auto" @click="closeDialog">Close</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
                    </v-col>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-col class="border-l-gray flex-grow-2" flat color="transparent">
                        <v-list-item three-line>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <h2>{{ ticket.subject}}</h2>
                                </v-list-item-title>
                                <v-list-item-subtitle v-if="ticket.description" class="mb-4" style="font-size: 1rem">
									<strong class="mr-2">Description:</strong>
									<span>{{ ticket.description }}</span>
								</v-list-item-subtitle>
                                <v-list-item-subtitle><strong>Ticket Number : {{ ticket.id }}</strong></v-list-item-subtitle>
                                <v-list-item-subtitle>Created : {{ formatDateDash(ticket.created_at) }}</v-list-item-subtitle>
                                <v-list-item-subtitle>Updated : {{ formatDateDash(ticket.updated_at) }}</v-list-item-subtitle>
                            
                            </v-list-item-content>
                        </v-list-item>
                        <div v-if="ticket.status != 'closed'">
							<QButton
								type="text"
								color="#1383EB"
								icon="fas fa-times"
								:disabled="ticketLoading"
								@click="closeTicketDialog = true"
							>
								Close Ticket
							</QButton>
                        </div>
                    </v-col>
                </v-row>
                <q-ticket-comment-list :ticket="ticket"></q-ticket-comment-list>
            </v-col>
            <v-col cols="12" sm="8" v-if="!loading &&  ticket.id > 0">
                <v-row>
                    <v-col class="pa-5" style="max-width:800px; border:1px #ccc solid; background-color:#fff;">
                        <v-textarea class="pa-5" v-model="comment" label="Reply to support"></v-textarea>
                        <div>
                            <span v-for="(attachment, key) in attachments" :key="key">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span :href="attachment.content_url" target="_blank" v-bind="attrs" v-on="on" :key="key" class="elevation-0 attachment-thumbnail-wrapper pa-3 ma-3">
                                            <v-icon color="primary" style="font-size:40px !important;">fas fa-file-alt fa-3x</v-icon>
                                        </span>
                                    </template>
                                    <span>{{ attachment.file_name }}</span>
                                </v-tooltip>
                            </span>
                        </div>
                        <div style="position:relative; max-width:300px;">
                            <q-file-upload class="ml-4" style="" ref="dropzone" text="Attach a file" :upload-url="'/api/my/tickets/' + ticket.id + '/attachment'" :params="{}" accepted-files="application/vnd.ms-excel, application/csv, application/vnd.ms-word,.csv,.txt,.jpg,.png,.gif,.docx,.doc,.xlsx,.pdf,.msg" upload-name="document" :max-files="null" v-on:complete="uploadComplete" v-on:fileadded="uploading=true;">
                            </q-file-upload>
                            <div style="position:absolute; top:0px; right:5px;">
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="warning" dark v-bind="attrs" v-on="on">
                                            fas fa-question-circle
                                        </v-icon>
                                    </template>
                                    <span>Click or drag attachments into the drop zone.<br />You must include a comment to SUBMIT</span>
                                </v-tooltip>
                            </div>
                        </div>
                        <v-btn class="ml-4 mt-4" :disabled=" uploading || (comment===null)" color="primary" sm max-width="200px" @click="submitComment">Submit</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
		<v-dialog v-model="closeTicketDialog" width="500">
			<v-card>
				<v-card-title class="headline grey lighten-2">Close Ticket</v-card-title>
				<v-card-text class="pt-2 body-1">
					Are you sure you want to solve this ticket? The home office team will no longer have access to addressing your request.
				</v-card-text>
				<v-card-actions>
					<QButton class="mr-2" type="text" color="#1383EB" @click="closeTicketDialog = false">Cancel</QButton>
					<QButton class="ml-auto" color="#1383EB" @click="closeTicket">Confirm</QButton>
				</v-card-actions>
			</v-card>
		</v-dialog>
    </v-container>
</template>
<script>
//table data + search
import QSupportTicketsDataTable from '@/components/datatables/QSupportTicketsDataTable.vue';
import QSupportSubmenu from '@/components/navigation/Submenus/QSupportSubmenu.vue';
import SupportTicket from '@/store/Models/SupportTicket'
import QFileUpload from '@/components/utils/QFileUpload.vue'
import QButton from '@/modules/shared/components/QButton.vue'
import QTicketCommentList from '@/components/lists/QTicketCommentList.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    data() {
        return {
            search: '',
            comment: null,
            attachments: [],
            uploading: false,
			dialog: false,
			closeTicketDialog: false,
            dialogText: null,
			ticketLoading: false,
            ticketError: false,
			currentDepartment: null,
			subject: null,
			categoryPage: null,
			onBehalfOfAgent: undefined,
        }
    },
    computed: {
        ticket: function() {
            if (this.$store.state.entities.support_ticket.current_ticket == null) {
                return {};
            }
            return this.$store.state.entities.support_ticket.current_ticket
        },
        loading: function() {
            return this.$store.state.entities.support_ticket.loading
        }
    },
    mounted: function() {
        this.refreshTicket(this.$route.params.id);
    },
    methods: {
        refreshTicket: function(id) {
            QuilityAPI.getTicket(id);
            this.comment = null;
            this.attachments = [];
        },
        submitComment: function() {
            var g = this;
            QuilityAPI.updateTicket(this.ticket.id, this.comment, this.attachments).then(function(json) {
                if (json.error) {
                    g.showError("Could not save ticket. " + json.message)
                    return;
                }
                g.comment = null
                g.refreshTicket(g.ticket.id);
                g.trackSupport("Comment Submitted")
            }).catch((err) => {
				if (err.isDemoMode) {
					this.showError(err.msg)
				}
            });
        },
        trackSupport: function(what) {
            gtag('event', 'Support', {
                'event_category': 'Ticket',
                'event_label': what
            });
        },
        uploadComplete: function(data) {
            this.uploading = false;
            this.attachments.push(data.data);
            this.$refs.dropzone.removeFile(data.file)
        }, 
		async closeTicket () {
			this.closeTicketDialog = false
			this.dialog 		= true
            this.ticketLoading 	= true
            this.dialogText 	= 'Closing Ticket...'
			try {
				const data 			= await QuilityAPI.closeTicket(this.ticket.id)
				this.ticketLoading 	= false
				this.dialogText 	= 'Closed!'
				this.clearTicketData()
				await QuilityAPI.getTickets()
				this.$router.push('/support/tickets')
			} catch (e) {			
				this.ticketLoading 	= false
				this.ticketError 	= true
				this.dialogText 	= `Problems! ${e.msg}`
			}
        },
		closeDialog () {
			this.ticketError 	= false
			this.dialog 		= false
		},
		clearTicketData () {
			this.currentDepartment 	= null
			this.comment 			= null
			this.subject 			= null
			this.categoryPage 		= null
			this.attachments 		= []
			this.onBehalfOfAgent 	= undefined
			this.dialog 			= false;
            this.ticketLoading 		= false;
            this.dialogText 		= null;
		}
    },
    components: {
        QSupportTicketsDataTable,
        QSupportSubmenu,
        QFileUpload,
        QTicketCommentList,
		QButton,
    },
    watch: {
        '$route.params.id': function(newV, old) {
            if (typeof newV != 'undefined') {
                var g = this;
                g.$nextTick(g.refreshTicket(newV));
            }
        },
    }
}

</script>

<style lang="scss">
.ticket-description {
	p:last-child {
		margin-bottom: 0;
	}
}

.ticket-message {
	margin-top: 1rem;
	font-size: 1.25em;
	line-height: 1.5;
    color: #444;
    font-weight: 700;
	text-align: center;
}
.v-dialog {
	.v-card {
		.card-title--success {
			background-color: #4caf50 !important;
			color: white;
		}
	}
}
</style>
